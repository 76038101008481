const rupaMigration = {
  placeholder: "This is an UNAUTHENTICATED page",
  description: "Welcome to the Rupa Migration",
  button: "Start Migration",
  connect: "Connect",
  next: "Next",
  getHelp: "Get Help",
  letsGetStarted: "Sounds good, let's get started!",
  supportInfo:
    "If you have any questions, please reach out to our Support team at {{supportPhone}} or email {{supportEmail}}",
  welcomeTitle: "Get ready to have everything in one place!",
  welcomeSubtitle: "A few things to know before you get started",
  card: {
    title: "Things to know about the move to Fullscript",
  },
  steps: {
    welcome: "Welcome",
    connectAccounts: "Connect Accounts",
    confirm: "Confirm",
  },
  items: {
    accountInfo: {
      title: "1. Your Rupa account information will be moved to Fullscript",
      details: {
        labs: "All labs you ordered on Rupa are available on Fullscript, with no change in price",
        orderHistory:
          "Your entire order history will be transferred over, even orders that are currently in progress",
        clientList: "Your entire client list will be transferred over",
        favorites: "Your Favorites, Blood Panels, and Bundles will be transferred over",
        snippets: "Your Saved Text Snippets will be transferred over",
      },
    },
    newOrders: {
      title: "2. All new lab orders will be placed on Fullscript",
      details: {
        allNewOrders: "All new lab orders and existing order updates will happen on Fullscript",
        viewOnly:
          "After you complete your move to Fullscript, your Rupa Health account will be in a view-only state",
        inactiveDate: "Your Rupa Health account will be made inactive in February, 2026",
      },
    },
    patients: {
      title: "3. Your patients will move with you",
      details: {
        viewOnly:
          "Your patients Rupa accounts will also be in view-only, and if they log in they'll be prompted to join you on Fullscript",
        unpaidOrders: "Unpaid orders will still be paid for on Rupa",
        newOrders: "Any new orders will be paid for on Fullscript",
        results: "All historical and new results will be available in Fullscript",
      },
    },
  },
} as const;

export { rupaMigration };
