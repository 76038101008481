export const restrictions = {
  Warning: "WARNING",
  CaliforniaWarning: "CALIFORNIA WARNING",
  Proposition65Warning: "Proposition 65 warning",
  Prop65Description:
    "Consuming this product can expose you to chemicals including lead, which is known to the State of California to cause cancer and birth defects or other reproductive harm. For more information visit: <2>www.p65warnings.ca.gov</2>",
  prop65message:
    "Consuming this product can expose you to chemicals, including {{ primaryChemical }}, which is known to the State of California to cause {{ riskList }}. For more information visit: ",
  prop65ExposureMessage:
    "This product can expose you to chemicals including {{ primaryChemical }}, which is known to the State of California to cause {{ riskList }}. For more information visit: ",
  OrderContainsProp65: "Some items in your order are subject to Prop 65 notice requirements.",
  shipTo: "Ship to:",
  skipForNow: "Skip for now",
  notSelected: "Not selected",
  notSelectedAbbr: "NA",
  updatedShippingState: "Updated shipping state successfully",
  updatedShippingStateError:
    "Error updating shipping state. Please reload your page, and try again",
  selectShippingLocation: "Select your shipping state",
  confirmStateHeader: "Confirm your shipping state for most accurate stock information",
  confirmStateBody:
    "In order to provide you the most accurate product stock information, please confirm the state you'll be shipping your orders.",
  ProductRemovedHeading: "{{productName}} is {{removeReason}} in your shipping location",
  ProductRemovedBodyPatient:
    "We've removed this product from your cart. Ask your practitioner to provide an alternate product.",
  SubscriptionProductBodyRemovedBodyPatient:
    "We've removed this product from your auto refills. Ask your practitioner to provide an alternate product.",
  ProductRemovedBodyUser:
    "We've removed this product from your cart. You can return to the catalog for similar products.",
  SubscriptionProductBodyRemovedBodyPractitioner:
    "We've removed this product from your auto refills. You can return to the catalog for similar products.",
  CartUpdatedBasedOnAvailability: "Cart updated based on availability",
  SubscriptionUpdateBasedOnAvailability: "auto refills updated based on availability",
  WeveRemovedFromYourCart: "We've removed from your cart:",
  WeveRemovedFromSubscription: "We've removed from your auto refills",
  ProductBackorderedBody:
    "This item may take longer to ship. If it isn't available within 21 days after purchase, we'll cancel the order, issue a refund, and inform you by email.",
  TakeLongerToShip: "It may take us a little longer to ship:",
  BackorderTooltip:
    "Backordered means that it may take us a little longer to ship this item to you. If 21 consecutive days pass after you checkout, we'll cancel the order and refund the amount to the card you paid with. We'll keep you updated by email",
  CartContinue: "Continue to checkout",
  BackToCart: "Back to cart",
  GotIt: " Got it",
  MutationSwapError:
    "There was an error updating your products. Please reload your page, and try again",
  unavailableInYourLocation: "Unavailable in your location",
  unavailableInYourShipppingLocation: "Unavailable in your shipping location",
  removalReason:
    "We've removed this product from your cart. You can return to the catalog for similar products.",
} as const;
