const credentials = {
  remove: "Remove",
  UploadAnother: "Upload another",
  uploadedSuccessfully: "Uploaded successfully",
  ReadyToUpload: "Ready to upload",
  UploadDescription:
    "File was attached, you can now upload it to the server, by clicking on the upload button.",
  fullscriptWillReview: {
    normal:
      "Fullscript will review your credentials and notify you once completed. On average, this will take up to two business days. Patients will be able to view your recommendations in the meantime, and orders can be placed from your store once you are approved.",
    dynamicTerm:
      "Fullscript will review your credentials and notify you once completed. On average, this will take up to two business days. {{capitalizedPatientsTerm}} will be able to view your {{treatmentPlanTerm}} in the meantime, and orders can be placed from your store once you are approved.",
    student:
      "Fullscript will review your student credentials and notify you once approved. On average, this will take up to two business days. After that, friends and family will be able to order supplements.",
    emerson:
      "Emerson Ecologics will review your credentials and notify you once completed. This may take up to two business days.",
  },
  weWillReview:
    "We'll review your credentials and notify you once completed. This will take up to two business days.",
  dragAndDrop: "Drag & drop credentials",
  dragAndDropStudent: "Drag & drop your proof of enrollment",
  yourProfessionalLicense: "Your professional license or permit to practice",
  otherFormats: "Your degree, diploma or applicable education",
  ACopyofStudentID:
    "A photo or scanned copy of your proof of enrollment (with your name, the program and school)",
  browseComputer: "Upload credentials",
  rejectedFilesError: "You must upload only one valid image or pdf file at a time",

  experiments: {
    certUpAddressBack: {
      license: "Preferred: Healthcare license or registration (with name and expiry)",
      otherFormats: "Other formats: Healthcare degree, diploma, certificate, etc.",
      preferred: "<1>Preferred:</1> Healthcare license or registration (with name and expiry)",
      formats: "<1>Other formats:</1> Healthcare degree, diploma, certificate, etc.",
    },
  },
  Upload: "Upload certification",
  CertTitle: "Professional certifications",
  CredentialFile: "Credential file",
  DateAdded: "Date added",
  Expiry: "Expiry date",
  Status: "Status",
  Empty: {
    Title: "Upload your professional certification.",
    Description:
      "Before you can order products, we <1>review your credentials</1> to ensure all healthcare providers meet Emerson Ecologics professional standards. This may take up to 1 business day.",
  },
} as const;

export { credentials };
