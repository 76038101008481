const sharedWarningBars = {
  certification: {
    hasCertifications:
      "<0>Your certification is pending review by our team. Orders through your dispensary will be shipped once we approve your certification.</0>",
    hasNoCertifications:
      "<0>Certification required.</0> Orders through your dispensary will not be shipped until you upload your certification and we approve it. <2><0>Upload certification \u00BB</0></2>",
    emerson: {
      hasNoApprovedCertifications:
        "<0>Certification required.</0> You cannot place orders until you upload your certification and we approve it. <2><0>Upload certification \u00BB</0></2>",
      hasNoCertifications: "Please provide additional information to place your first order.",
      hasCertifications:
        "Your certification is pending review by our team. Orders through your business will be shipped once we approve your certification.",
    },
    cart: {
      hasCertifications:
        "Your certification is pending review by our team. Once approved, you'll be able to place your order. This typically takes 24 hours.",
      hasNoApprovedCertifications:
        "To place orders, you will need to <1>upload your professional credentials</1>",
      notUploadedYet: "To place orders, you will need to <1>complete account setup</1>",
    },
  },
  credentials: {
    congratsOnGraduating:
      "<0>Congratulations on graduating!</0> Please upload your new certification. <2><0>Upload certification \u00BB</0></2>",
    hasCredentials:
      "<0>Your student credentials are pending review.</0> Orders through your dispensary will be shipped once we approve your credentials.",
    hasNoCredentials:
      "<0>Student Credentials required.</0> Orders will not be shipped until you upload your credentials and we approve it. <2><0>Upload credentials \u00BB</0></2>",
  },
};

export { sharedWarningBars };
