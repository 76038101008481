const blackFridayCyberMonday = {
  searchBanner: {
    title: "<strong>Enjoy extra savings during our Black Friday and Cyber Monday Sale</strong>",
    subtitle:
      "Get 20% off select brands and products, on a single order until December 3 at 2:59a.m. ET.<br /> Use promotion code: BFCM2024",
    cta: "Copy code",
    moreDetails: "More details",
    confirmationToast: "Code copied",
  },
  PromoCarousel: {
    Title: "Wholesale's Black Friday and Cyber Monday Sale is on now",
    Description:
      "Get 20% off select brands and products, on a single order until December 3 at 2:59a.m. ET",
    ShopWholesale: "Shop Wholesale",
  },
  promoInfo:
    "Use promo code <strong>BFCM2024</strong> for an extra 20% off select brands and products, until December 3 at 2:59a.m. ET. One time use only.",
  moreDetails: "More details",
} as const;

export { blackFridayCyberMonday };
