const landing = {
  Welcome: "Welcome to Fullscript + {{ displayName }}",
  CraftPersonalized:
    "Craft personalized supplement plans that build trusted relationships with your patients — right from {{ displayName }}.",
  SignIn: "Sign in",
  DontHaveAccountYet: "Don't have an account yet?",
  AskYourAdmin:
    "Ask your Fullscript account owner to add you to your practice's Fullscript account.",
};

export { landing };
