const patientPicker = {
  SearchForPatient: "Search for a {{ patientTerm }}",
  CreateNewPatient: "Create new {{ patientTerm }}",
  CreateNewPatientWithButton: "Create new {{ patientTerm }} with the button above",
  BackToSearch: "Back to search",
  SelectAPatient: "Select a {{ patientTerm }}",
  SearchForYourPatients:
    "Search for your {{ pluralPatientTerm }} who already have a Fullscript account or create a new {{ patientTerm }}.",
  MatchToOrCreateNewPatient:
    "Match to a {{ patientTerm }} that already has a Fullscript account or create a new {{ patientTerm }}.",
  PrescriptionOnBehalfOf:
    "<0>{{treatmentPlanPluralized}}</0> written on behalf of <2>{{- practitionerFullName}}</2>",
  MultiplePatientsNamed:
    "You have multiple <1>{{ patientTerm }}</1> named <3><0>{{- patientName }}</0></3>, please confirm which you are looking to write a <6>{{ treatmentPlanTerm }}</6> for:",
  SinglePatientNamed:
    "You have one <1>{{ patientTerm }}</1> named <3><0>{{- patientName }}</0></3>, please confirm they are the <1>{{ patientTerm }}</1> you are looking to write a <6>{{ treatmentPlanTerm }}</6> for:",
  ActiveTreatmentPlan: "Active {{treatmentPlanTerm}}",
  ActiveTreatmentPlanColon: "Active {{treatmentPlanTerm}}: <4><0>{{totalCount}}</0></4>",
  SelectFullscriptPatient: "Select Fullscript {{patientTerm}}",
  CouldntFindPatientsWithName:
    'We couldn\'t find any {{ pluralPatientTerm }} with the name "{{- patientName }}"',
  SearchForOrCreateNewPatient:
    "Please try searching for a different {{ patientTerm }} or create a new {{ patientTerm }} using the button above.",
  Select: "Select",
  CreatePatientViaEHR:
    "Create a new {{ patientTerm }} within your Fullscript dispensary and send them {{ treatmentPlanTerm }} through your EHR.",
  ConfirmPatientMatch: "Confirm {{ patientTerm }} match",
  MatchPatientContent:
    "Please confirm that the Fullscript {{ patientTerm }} <2>{{ targetPatientName }}</2> should be matched to the external {{ patientTerm }} <5>{{ givenPatientName }}</5>.",
  MatchPatient: "Match {{ patientTerm }}",
} as const;

export { patientPicker };
