import type { InitOptions } from "i18next";

import { isDevelopment, isSSR, isStaging } from "@shared/utils/fullscriptEnv/fullscriptEnv";

export const baseI18nConfig: InitOptions = {
  compatibilityJSON: "v3",
  debug: (isDevelopment() || isStaging()) && !isSSR(),
  defaultNS: "common",
  fallbackNS: "common",
  lng: "en",
  supportedLngs: ["en"],
  fallbackLng: "en",
};
