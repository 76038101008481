export const mfa = {
  title: "Multi-factor authentication",

  backupCodeForm: {
    backupCodeIncorrect: "Backup code was incorrect. Try again.",
    subtitle: "Enter your backup code to sign in.",
    title: "Enter your backup code",
  },
  chooseAuthenticationMethod: {
    generateCodeViaAuthenticatorApp: "Generate a one-time code using an authenticator app",
    moreSecure: "More secure",
    receiveOneTimeCodeViaEmail: "Receive a one-time code through your email",
    receiveOneTimeCodeViaTextMessage: "Receive a one-time code through your mobile phone",
    thisIsMandatory: "This is a mandatory step to add an extra layer of security.",
    title: "Secure your Fullscript account",
    changeTitle: "Change multi-factor authentication method",
    subtitle:
      "Secure your Fullscript account by using a one-time code in addition to your password when signing in.",
    useAuthenticatorApp: "Use an authenticator app",
    useTextMessage: "Use text message",
    useEmail: "Use the email linked to your Fullscript account",
    emailMFAAlreadyEnabled: "Email MFA is already enabled",
    willBeMandatorySoon:
      "<0>This will be mandatory soon.</0> Set up today to add an extra layer of security.",
  },
  displayBackupCode: {
    backupCodeDescription:
      "Below is your backup code. We recommend securely storing it by taking a screenshot or writing it down in a safe place. Backup codes can only be used once.",
    copyBackupCode: "Copy backup code",
    enableComplete:
      "Each time you sign-in, we'll ask you for a one-time code in addition to your password to confirm your identity. <1>Learn more</1>",
    important: "Important",
    title: "Multi-factor authentication is enabled",
  },
  downloadAuthenticator: {
    downloadAnAuthenticatorApp:
      "Download an authenticator app from the the app store on your mobile device or tablet.",
    googleAuthenticator: "Google Authenticator",
    lastPassAuthenticator: "LastPass Authenticator",
    microsoftAuthenticator: "Microsoft Authenticator",
    openTheApp:
      'Open the app, follow the installation process, and click "Next" to receive your {{platform}} QR code.',
    title: "Download an authenticator app",
    twilioAuthyAuthenticator: "Twilio Authy Authenticator",
  },
  enableWithAuthenticator: {
    title: "Enable multi-factor authentication with an app",
    subtitle:
      "Use a one-time code from an authenticator app. To get started, install an authenticator app.",
    scanTheQrCode: "Scan the QR code or manually enter the code in your authenticator app.",
  },
  enableWithEmail: {
    title: "Enable multi-factor authentication with your email",
    subtitle: "Use a one-time code from your email along with your password.",
    enterCodeFromEmail: "Enter the one-time code from the email sent to you by Fullscript.",
    codeSentToEmail:
      "A one-time code has been sent to your email <strong>{{emailMasked}}</strong>.",
  },
  enableWithSMS: {
    title: "Enable multi-factor authentication with your mobile phone",
    subtitle: "Use a one-time code from your mobile number along with your password.",
    enterCodeFromSms: "Enter the one-time code from the text message sent to you by Fullscript.",
    codeSentToMobileNumber:
      "A one-time code has been sent to your mobile number ending in <strong>{{phoneLast4}}</strong>.",
  },
  enterMobilePhone: {
    cta: "Send code",
    disclaimer:
      "Using your mobile number for security means you accept our <1>privacy policy</1> and <3>Terms of Service</3>. Standard text message rates may apply.",
    enterYourNumber: "Enter your mobile number to receive a one-time code.",
    invalidNumberError: "Please provide a valid mobile number.",
    number: "Mobile number",
    subtitle: "Use a one-time code from your mobile number along with your password.",
    title: "Enable multi-factor authentication with your mobile phone",
    tooLongError: "Phone number must have at most 10 digits",
  },
  newBackupCodeView: {
    subtitle:
      "<0>Here is your new backup code. Please store it securely.</0> <1>You can view this again in your {{storePlatform}} <2><0>Account settings</0></2>.</1>",
  },
  resendOtp: {
    codeWillExpireIn10: "This code will expire in 10 minutes.",
    didntGetSMS: "Didn't get the text message? <resendLink>Resend</resendLink>.",
    didntGetEmail:
      "Didn't get the email? Check your spam folder or <resendLink>resend</resendLink>.",
    enterCodeFromFullscript: "Enter the one-time code from the text sent to you by Fullscript.",
    otpNotSent: "An error occurred while sending your {{ otpMethodName}}. Please try again.",
    smsSent: "A text message has been sent to your mobile number ending in {{phoneLast4}}.",
    emailSent: "An email has been sent to {{email}}.",
  },
  switchMFAOption: {
    subtitle:
      "Secure your Fullscript account by using a one-time code in addition to your password when signing in.",
    title: "Change multi-factor authentication method",
  },
  VerifyMFA: {
    enterOneTimeCodeApp: "Enter the one-time code from your authenticator app.",
    enterOneTimeCodeEmail:
      "A one-time code has been sent to your email <strong>{{emailMasked}}</strong>.",
    oneTimeCode: "One-time code",
    title: "Verify your account with multi-factor authentication",
    unableToAccessApp: "Unable to access your authenticator app? Use your backup code.",
    unableToAccessSMS: "Unable to access your text messages? Use your backup code.",
    unableToAccessEmail: "Unable to access your email account? Use your backup code.",

    verifyToChangeMFA: "To change multi-factor authentication methods, please verify your account.",
  },
  VerifyMFAForm: {
    backToSignIn: "Back to Sign in",
    codeWillExpire: "This code will expire in 10 minutes.",
    enterYourCode: "Enter your code",
    errorMessage: "An error occurred while authenticating your account. Please try again.",
    oneTimeCode: "One-time code",
    oneTimeCodeMismatch: "The code does not match. Try again.",
    otpRetrieval: {
      app: "Enter the one-time code from your authenticator app to sign in.",
      email:
        "A one-time code has been sent to your email <strong>{{emailMasked}}</strong> to sign in.",
      sms: "A one-time code has been sent to your mobile number ending in <strong>{{mobileNumber}}</strong>. Enter the code below to sign in.",
    },
    unableToAccessOtpMethod: {
      app: "Unable to access your authenticator app?",
      email: "Unable to access your email account?",
      sms: "Unable to access your text messages?",
    },
    useBackupCodeToSignIn: "Use backup code to sign in",
  },
  authenticatorApp: "Authenticator app",
  backupCode: "Backup code",
  change: "Change",
  codeDoesNotMatch: "The code does not match",
  copyCode: "Copy code",
  currentAuthMethod: "Current authentication method",
  didntGetEmail: "Didn't get the email? Check your spam folder or <1>resend</1>.",
  didntGetTextMessage: "Didn't get the text message? <1>Resend</1>.",
  disable: "Disable multi-factor authentication",
  disableDescription:
    "This will remove a layer of security protecting your account from unauthorized access. Once disabled you'll use your password to sign in.",
  disableSuccess: "Multi-factor authentication has been disabled",
  email: "Email",
  emailToAddress: "Email sent to {{ email }}",
  emailHasBeenSent: "An email has been sent to {{ email }}",
  emailNotSent: "An error occurred while sending your email. Please try again.",
  enable: "Enable multi-factor authentication",
  enableComplete:
    "Each time you sign-in, we'll ask you for a one-time code in addition to your password to confirm your identity.",
  enableSuccess: "Multi-factor authentication has been enabled",
  enableWithApp: "Enable multi-factor authentication with an app",
  enableWithMobilePhone: "Enable multi-factor authentication with your mobile phone",
  enterCodeFromAuthenticator: "Enter the one-time code you see in your authenticator app.",
  errorOccurredTryAgain: "An error has occurred. Please try again.",
  invalidOTP: "Invalid one-time code. Please try again.",
  isEnabled: "Multi-factor authentication is enabled",
  manage: "Manage multi-factor authentication",
  manualSetupCodeCopied: "Manual setup code successfully copied",
  maxAttemptsExceeded: "Maximum number of attempts exceeded. Please try again in 10 minutes.",
  notEnabled: "Multi-factor authentication is not enabled",
  oneTimeCode: "One time code",
  secureYourAccount:
    "Secure your account data with a one-time code after sign-in to protect against unauthorized access.",
  setUp: "Set up",
  skipForNow: "Skip for now",
  step1: "Step 1.",
  step2: "Step 2.",
  textMessage: "Text message",
  textMessageHasBeenSent:
    "A text message has been sent to the mobile number ending in {{ phoneLast4 }}",
  textMessageNotSent: "An error occurred while sending your text message. Please try again.",
  textMessageSent: "A text message has been sent to your mobile number ending in {{phoneLast4}}.",
  textMessageToLast4Digits: "Text message sent to phone number ending in {{phoneLast4}}",
  trustThisDevice: "Trust this device",
} as const;
